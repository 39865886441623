import { HttpClient } from "@angular/common/http";
import { environment } from "@environment";
import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";

export interface Mail {
    name?: string;
    from?: string;
    to?: string;
    subject: string;
    text?: string;
    html?: string;
}

@Injectable({ providedIn: "root" })
export class MailService {
    constructor(private platform: Platform, private http: HttpClient) {}

    send(mail: Mail): Promise<any> {
        mail = this.addMetaInfo(mail);
        return this.http.post("propar.api.contact.v1", mail).toPromise();
    }

    addMetaInfo(mail: Mail) {
        mail = Object.assign({}, mail);
        const platforms = this.platform.platforms().join(" ");

        if (mail.text) {
            mail.text =
                `Message:\n` +
                `${mail.text}\n\n` +
                `Version: ${environment.appVersion}\n` +
                `Platforms: ${platforms}\n`;
        }

        if (mail.html) {
            mail.html =
                `Message:<br>` +
                `${mail.html}<br><br>` +
                `Version: ${environment.appVersion}<br>` +
                `Platforms: ${platforms}<br>`;
        }

        return mail;
    }
}
