import { Injectable } from "@angular/core";
import { Action, Reduce } from "@tcn/ngx-redux-store-config";
import { from, Observable, of } from "rxjs";
import { delay } from "rxjs/operators";
import { BookingFilter } from "../../models/booking-filter.model";
import { AppState } from "../app-state";
import { INITIAL_STATE } from "../initital-state";
import { LocalStorage } from "@app/services/local-storage.service";

@Injectable()
export class BookingApi {
    constructor(private storage: LocalStorage) {}

    @Action(null, { cache: { expires: false, return: "bookingFilters" } })
    loadFilters(): Promise<BookingFilter[]> {
        return this.storage.get("booking.filters").then((filters) => {
            const result = (filters || []).map(
                (filter) => new BookingFilter(filter)
            );

            return result;
        });
    }

    @Action()
    saveFilters(filters: Array<BookingFilter>): Observable<BookingFilter[]> {
        return from(this.storage.set("booking.filters", filters));
    }

    @Action()
    currentFilter(filter: BookingFilter): Observable<BookingFilter> {
        return of(filter).pipe(delay(100));
    }

    @Reduce(["loadFilters", "saveFilters"])
    protected reduceFilters(state = INITIAL_STATE, action): AppState {
        return {
            ...state,
            bookingFilters: action.payload.map(($) => new BookingFilter($)),
        };
    }

    @Reduce()
    protected _currentFilter(state = INITIAL_STATE, action): AppState {
        return {
            ...state,
            currentBookingFilter: new BookingFilter(action.payload),
        };
    }
}
