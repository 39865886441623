import { Component, Input, OnInit } from "@angular/core";
import { isEmpty } from "@tcn/ngx-common";
import { Tag } from "@yps/neonis-api-ngx";

@Component({
    selector: "tag",
    templateUrl: "./tag.component.html",
    styleUrls: ["./tag.component.scss"]
})
export class TagComponent implements OnInit {
    @Input()
    set tag(tag: Tag) {
        if (!tag) {
            return;
        }

        this.name = tag.name;

        if (!isEmpty(this.name)) {
            if (this.name.endsWith("!!")) {
                this._color = this._color || "danger";
                this.name = this.name.slice(0, -2);
            }

            if (this.name.endsWith("!")) {
                this._color = this._color || "warning";
                this.name = this.name.slice(0, -1);
            }
        }
    }

    @Input()
    defaultColor: string;

    @Input()
    set color(color: string) {
        this._color = color;
    }

    get color(): string {
        return this._color || this.defaultColor;
    }

    name: string;
    private _color: string;

    constructor() {}

    ngOnInit() {}
}
