import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-position-selection",
  templateUrl: "./position-selection.page.html",
  styleUrls: ["./position-selection.page.scss"]
})
export class PositionSelectionPage implements OnInit {
  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  cancel() {
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  save() {
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }
}
