import { select } from "@angular-redux/store";
import { Component, OnInit } from "@angular/core";
import { StatusBar, Style as StatusBarStyle } from "@capacitor/status-bar";
import { SplashScreen } from "@capacitor/splash-screen";
import { Keyboard, KeyboardResize } from "@capacitor/keyboard";
import { Platform } from "@ionic/angular";
import { LocalizationService } from "@tcn/ion-common";
import { ApiConfigService, LocaleConfigService } from "@tcn/ngx-common";
import { Observable } from "rxjs";
import { locale as localDE } from "./i18n/de";
import { locale as localEN } from "./i18n/en";
import { Settings } from "./models";
import { ViewModeService } from "./services/viewmode.service";
import { SettingsApi } from "./store/api/settings.api";

@Component({
    selector: "app-root",
    templateUrl: "app.component.html",
    styleUrls: ["app.component.scss"],
})
export class AppComponent implements OnInit {
    @select()
    settings$: Observable<Settings>;
    settings: Settings;

    constructor(
        private platform: Platform,
        private localization: LocalizationService,
        private localeConfig: LocaleConfigService,
        private settingsApi: SettingsApi,
        private apiConfigService: ApiConfigService,
        private viewMode: ViewModeService,
    ) {}

    async ngOnInit() {
        await this.initializeApp();
    }

    async initializeApp() {
        await this.platform.ready();
        const preferredLang = await this.initializeLocalization();

        if (this.platform.is("capacitor")) {
            // Hide the splash screen
            await SplashScreen.hide();

            // Set keyboard resize mode
            await Keyboard.setResizeMode({ mode: KeyboardResize.Ionic });

            // Set status bar style
            await StatusBar.setStyle({ style: StatusBarStyle.Light });

            // Set status bar background color
            await StatusBar.setBackgroundColor({ color: "#ffffff" });

            if (this.platform.is("android")) {
                // Set overlays web view for android
                await StatusBar.setOverlaysWebView({ overlay: false });
            }
        }

        this.initializeViewMode();

        this.settings$.subscribe((settings) => {
            if (settings) {
                this.settings = settings;
                this.updateApiConfig(settings);

                // Update locale
                if (!settings.locale) {
                    settings.locale = preferredLang;
                }

                if (this.localization.getActiveLanguage() !== settings.locale) {
                    this.localization.setActiveLanguage(settings.locale);
                    this.localeConfig.setActiveLanguage(settings.locale);
                }

                // Update Theme
                if (settings.theme !== "auto") {
                    this.viewMode.toggleDarkMode(
                        settings.theme === "dark" ? true : false
                    );
                }
            }
        });

        await this.settingsApi.loadSettings();
    }

    initializeLocalization() {
        this.localization.initializeLanguages([localEN, localDE], "en-EN");

        return this.localization.getPreferredLanguage().then((lang) => {
            if (lang.indexOf("-") < 0) {
                lang = lang + "-" + lang.toUpperCase();
            } else {
                const langParts = lang.split("-");
                lang = langParts[0] + "-" + langParts[1].toUpperCase();
            }

            this.localization.setActiveLanguage(lang);
            this.localeConfig.setActiveLanguage(lang);

            return lang;
        });
    }

    initializeViewMode() {
        const defaultDarkMode = this.viewMode.isDarkModeEnabled();
        this.viewMode.toggleDarkMode(defaultDarkMode);

        this.viewMode.darkMode$.subscribe((darkMode) => {
            if (this.settings && this.settings.theme !== "auto") {
                darkMode = this.settings.theme === "dark" ? true : false;
            }

            this.viewMode.toggleDarkMode(darkMode);
        });
    }

    updateApiConfig(settings: Settings) {
        this.apiConfigService.set(settings.neonisApi);
        this.apiConfigService.set(settings.proparApi);
        this.apiConfigService.set(settings.webdavApi);
    }
}
