import { ImageViewerPage } from "./../image-viewer/image-viewer.page";
import { Component, Input, OnInit } from "@angular/core";
import { CategorySelectionPage } from "@app/modals/category-selection/category-selection.page";
import { PositionSelectionPage } from "@app/modals/position-selection/position-selection.page";
import { TagSelectionPage } from "@app/modals/tag-selection/tag-selection.page";
import { environment } from "@environment";
import { ModalController } from "@ionic/angular";
import { BlockUntilFinished } from "@tcn/ngx-common";
import { Image } from "@yps/neonis-api-ngx";
import { catchError, map } from "rxjs/operators";
import { Alerts } from "./../../services/alerts";
import { ImageService } from "../../services/image.service";
import { BackActionService } from "@app/services/back-action.service";

@Component({
    selector: "app-image-details",
    templateUrl: "./image-details.page.html",
    styleUrls: ["./image-details.page.scss"],
})
export class ImageDetailsPage implements OnInit {
    @Input()
    title = "Image";

    @Input()
    image = new Image();

    @Input()
    editMode = false;

    @Input()
    editEnabled = true;

    @Input()
    deleteEnabled = true;

    @Input()
    allowNewTags = false;

    @Input()
    showName = true;

    @Input()
    showTags = true;

    @Input()
    showNote = true;

    @Input()
    closeOnSave = false;

    @Input()
    disableCategory = false;

    categories = environment.imageCategories;
    saving: boolean;

    private original: Image;
    private updated = false;
    private _imageSrc: any = null;

    get imageSrc() {
        if (!this._imageSrc && this.image.src) {
            this._imageSrc = this.image.thumb.src;
        }

        return this._imageSrc;
    }

    constructor(
        private backActions: BackActionService,
        private alerts: Alerts,
        private modalCtrl: ModalController,
        private imageService: ImageService
    ) {}

    ngOnInit() {}

    ionViewWillEnter() {
        this.backActions.push(() => {
            if (this.editMode) {
                this.cancel();
            }
        });
    }

    ionViewDidLeave() {
        this.backActions.pop();
    }

    close(deleted = false) {
        this.modalCtrl.dismiss({
            saved: this.updated,
            deleted,
            image: this.image,
        });
    }

    edit() {
        this.editMode = true;
        this.original = this.image;
        this.image = new Image(this.image);
    }

    @BlockUntilFinished({ state: "saving" })
    save() {
        return this.imageService.saveImage(this.image).pipe(
            map((doc) => {
                this.editMode = false;
                this.updated = true;
                this.image = new Image(doc);

                if (this.closeOnSave) {
                    this.close();
                }
            }),
            catchError(() => this.alerts.showSaveError())
        );
    }

    cancel() {
        this.alerts.showCancelConfirmation().then((cancel) => {
            if (cancel) {
                this.saving = false;
                this.editMode = false;

                if (!this.original) {
                    this.close();
                } else {
                    this.image = this.original;
                }
            }
        });
    }

    async takeImage() {
        this.imageService.takeImage().subscribe((image) => {
            this._imageSrc = image.src;
            this.image.content = image.content;
            this.image.mimeType = image.mimeType;
        });
    }

    async deleteImage() {
        const alert = await this.alerts.ctrl.create({
            header: "DeleteImageHeader",
            message: "DeleteImageMessage",
            buttons: [
                {
                    text: "Cancel",
                    role: "cancel",
                    cssClass: "secondary",
                },
                {
                    text: "Ok",
                    handler: () => {
                        this.imageService
                            .deleteImage(this.image)
                            .subscribe(() => {
                                this.close(true);
                            });
                    },
                },
            ],
        });

        await alert.present();
    }

    async openCategorySelection() {
        if (!this.editMode) {
            return;
        }

        const modal = await this.modalCtrl.create({
            component: CategorySelectionPage,
        });
        return await modal.present();
    }

    async openPositionSelection() {
        if (!this.editMode) {
            return;
        }

        const modal = await this.modalCtrl.create({
            component: PositionSelectionPage,
        });
        return await modal.present();
    }

    async openImageViewer() {
        const modal = await this.modalCtrl.create({
            component: ImageViewerPage,
            cssClass: "modal-fullscreen",
            componentProps: {
                image: this.image,
            },
        });

        modal.present();
    }

    async openTagSelection() {
        if (!this.editMode) {
            return;
        }

        const modal = await this.modalCtrl.create({
            component: TagSelectionPage,
            backdropDismiss: false,
            presentingElement: await this.modalCtrl.getTop(),
            componentProps: {
                allowNewTags: this.allowNewTags,
                selected: this.image.tags,
            },
        });

        modal.onDidDismiss().then((result) => {
            if (!result.data.cancelled) {
                this.image.tags = result.data.selected;
            }
        });

        return await modal.present();
    }
}
