import { Injectable } from "@angular/core";
import { Action, Reduce } from "@tcn/ngx-redux-store-config";
import { from, Observable, of } from "rxjs";
import { delay } from "rxjs/operators";
import { VehicleFilter } from "../../models/vehicle-filter.model";
import { AppState } from "../app-state";
import { INITIAL_STATE } from "../initital-state";
import { LocalStorage } from "@app/services/local-storage.service";

@Injectable()
export class VehicleApi {
    constructor(private storage: LocalStorage) {}

    @Action(null, { cache: { expires: false, return: "vehicleFilters" } })
    loadFilters(): Promise<VehicleFilter[]> {
        return this.storage.get("vehicles.filters").then((filters) => {
            const result = (filters || []).map(
                (filter) => new VehicleFilter(filter)
            );

            return result;
        });
    }

    @Action()
    saveFilters(filters: Array<VehicleFilter>): Observable<VehicleFilter[]> {
        return from(this.storage.set("vehicles.filters", filters));
    }

    @Action()
    currentFilter(filter: VehicleFilter): Observable<VehicleFilter> {
        return of(filter).pipe(delay(100));
    }

    @Reduce(["loadFilters", "saveFilters"])
    protected reduceFilters(state = INITIAL_STATE, action): AppState {
        return {
            ...state,
            vehicleFilters: action.payload.map(($) => new VehicleFilter($)),
        };
    }

    @Reduce()
    protected _currentFilter(state = INITIAL_STATE, action): AppState {
        return {
            ...state,
            currentVehicleFilter: new VehicleFilter(action.payload),
        };
    }
}
