import { AppState } from "./app-state";
import { INITIAL_STATE as STORAGE_INITIAL_STATE } from "@storage/store";
import { Settings } from "@app/models";

export const INITIAL_STATE: AppState = Object.assign(
    {},
    STORAGE_INITIAL_STATE,
    {
        loading: false,
        error: null,
        settings: new Settings(),
        tags: [],
        services: [],
        resources: [],
    }
);
