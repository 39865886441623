import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-category-selection",
  templateUrl: "./category-selection.page.html",
  styleUrls: ["./category-selection.page.scss"]
})
export class CategorySelectionPage implements OnInit {
  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  cancel() {
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }

  save() {
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }
}
