import { TagComponent } from "./tag.component";
import { NgModule } from "@angular/core";
import { SharedModule } from "@app/shared.module";

@NgModule({
    imports: [SharedModule],
    exports: [TagComponent],
    declarations: [TagComponent]
})
export class TagModule {}
