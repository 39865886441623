export const locale = {
    lang: "en-EN",
    data: {
        Signin: "Signin",
        Signout: "Signout",
        Tenant: "Tenant",
        Username: "Username",
        Password: "Password",
        InvalidCredentialsMessage: "Invalid credentials",

        Settings: "Settings",
        Filter: "Filter",
        Saved: "Saved",
        Sorting: "Sorting",
        SortBy: "Sort by",
        SortOrder: "Sort order",
        Ascend: "Ascend",
        Descend: "Descend",
        Status: "Status",
        Condition: "Condition",
        Tags: "Tags",
        AddTag: "Add Tag",
        Options: "Options",
        Number: "Nummer",
        Summer: "Summer",
        SUMMER: "Summer",
        Winter: "Winter",
        WINTER: "Winter",
        AllSeason: "All Season",
        ALLSEASON: "All Season",
        Year: "Year",
        Years: "Year's",
        Age: "Age",
        Overdue: "Overdue",
        NotOverdue: "Not Overdue",
        Name: "Name",
        All: "All",
        Mark: "Mark",
        Marked: "Marked",
        Search: "Search",
        Common: "Common",
        Image: "Image",
        Images: "Images",
        Materials: "Materials",
        HubCaps: "Hubcaps",
        RimLocks: "Rimlocks",
        WheelNuts: "Wheelnuts",
        Stored: "Stored",
        NotStored: "Not stored",
        AtCustomerSite: "At customer site",
        Tyres: "Tyres",
        Rims: "Rims",
        Front: "Front",
        Rear: "Rear",
        FrontLeft: "Front Left",
        FrontRight: "Front Right",
        RearLeft: "Rear Left",
        RearRight: "Rear Right",
        Customer: "Customer",
        Vehicle: "Vehicle",
        Booking: "Booking",
        Bookings: "Bookings",
        NextBooking: "Next Booking",
        Company: "Company",
        Mr: "Mr",
        Mrs: "Mrs",
        Address: "Address",
        Birthday: "Birthday",
        ContactInfo: "Contact",
        Phone: "Phone",
        MobileNumber: "Mobile",
        Email: "Email",
        Note: "Note",
        Today: "Today",
        Week: "Week",
        Month: "Month",
        Tomorrow: "Tomorrow",
        OClock: "o'clock",
        Designation: "Designation",
        NewImage: "New Image",
        Type: "Type",
        Street: "Street",
        Zipcode: "Zipcode",
        City: "City",
        Housenumber: "Housenumber",
        Category: "Category",
        CreatedOn: "Created On",
        CreateBy: "Created By",
        UpdatedOn: "Updated On",
        UpdatedBy: "Updated By",
        Branch: "Branch",
        Counts: "Quantity",
        FrontSide: "Front side",
        BackSide: "Back side",
        Automatically: "Automatically",
        Without: "Without",
        Actions: "Actions",
        Months: "Month's",
        till: "till",
        Diameter: "Diameter",
        Total: "Total",
        Salutation: "Salutation",
        Title: "Title",
        Camera: "Camera",
        Gallery: "Gallery",
        Send: "Send",
        Theme: "Theme",
        Dark: "Dark",
        Light: "Light",
        Standard: "Standard",
        Enabled: "Enabled",
        Color: "Farbe",
        ColorCode: "Farbcode",
        TakeOverData: "Take over data",
        TakeOverMileage: "Take over mileage",
        Reports: "Reports",
        Available: "Available",
        Date: "Date",
        State: "State",
        CustomerNumber: "Customer number",
        CustomerName: "Customer name",
        WheelStorageNumber: "Wheel Storage Number",
        Location: "Location",

        // Actions
        Yes: "Yes",
        No: "No",
        Cancel: "Cancel",
        Back: "Back",
        Ok: "OK",
        Start: "Start",
        Stop: "Stop",
        Finish: "Finish",
        Finished: "Finished",
        Add: "Add",
        Delete: "Delete",
        Remove: "Remove",
        Save: "Save",
        Close: "Close",
        Apply: "Apply",
        Reset: "Reset",
        Rename: "Rename",
        Edit: "Edit",
        AddMark: "Mark",
        PickedUp: "Picked Up",
        NotPickedUp: "Not Picked Up",
        TakeImage: "Take Image",
        Update: "Update",
        Refresh: "Refresh",
        Next: "Next",
        View: "View",
        ViewAll: "View all",
        ShowMessages: "Show Messages",
        HideMessages: "Hide Messages",

        // Tab titles
        DashboardTabTitle: "Dashboard",
        StoragesTabTitle: "Storages",
        ReportsTabTitle: "Reports",
        SettingsTabTitle: "Settings",
        InfoTabTitle: "Info",

        // Page titles
        SettingsPageTitle: "Settings",
        StorageListPageTitle: "Wheel Storages",
        StorageDetailsPageTitle: "Wheel Storage",
        StorageReportsPageTitle: "Reports/Wheel Storages",
        InfoPageTitle: "Info",
        ContactPageTitle: "Contact",
        VehicleRegistrationPageTitle: "Vehicle registrations",
        FleetCardPageTitle: "Fleet Card",
        SearchPageTitle: "Search",
        AddStoragePageTitle: "New Storage",
        VehicleTypeSearchPageTitle: "Vehicle Type",
        VehicleScanPageTitle: "Vehicle Scan",
        BookingListPageTitle: "Bookings",
        BookingDetailsPageTitle: "Booking",
        CustomerListPageTitle: "Customers",
        CustomergDetailsPageTitle: "Customer",
        VehicleListPageTitle: "Vehicles",
        VehicleDetailsPageTitle: "Vehicle",
        ChatPageTitle: "Chat",
        AddVehiclePageTitle: "Add Vehicle",

        // Alerts
        SigninErrorHeader: "Error",
        SigninErrorMessage:
            "There was an error registering. Please check your data and try again.",

        SignoutHeader: "Signout",
        SignoutMessage: "Are you sure you want to signout?",

        SaveErrorHeader: "Error",
        SaveErrorMessage:
            "An error occurred while saving. Please try again later.",

        CommonErrorHeader: "Error",
        CommonErrorMessage: "An error has occurred. Please try again later.",

        CancelConfirmationHeader: "Cancel",
        CancelConfirmationMessage: "Are you sure you want to cancel?",

        ClearFilterHeader: "Reset",
        ClearFilterMessage: "Would you like to reset the filter?",

        // Contact page
        InterestedIn: "Interested in...",
        Bug: "Bug",
        SuggestionForImprovement: "Suggestion for improvement",
        Question: "Question",
        Other: "Other",
        Message: "Message",
        ContactTitle: "Questions, requests or criticism?",
        ContactSubtitle: "We look forward to hearing from you.",
        ContactSuccessHeader: "Success",
        ContactSuccessMessage:
            "We have successfully received your message. Thank you for your interest.",

        // Settings page
        Language: "Language",
        German: "German",
        English: "English",
        DarkMode: "Dark mode",
        ShowNotesByDefault: "Show notes by default",
        User: "User",

        // Info page
        RateApp: "Rate App",
        Contact: "Contact",
        VersionInfo: "Version-Info",
        Legal: "Legal",
        BusinessTerms: "Business Terms",
        LegalNotice: "Legal Notice",
        PrivacyPolicy: "Privacy Policy",

        // Customers
        Customers: "Customers",
        CustomerHasBirthday: "Customer has birthday",
        NewCustomer: "New customer",
        ExistingCustomer: "Existing Customer",
        CustomerSearch: "Search customer",
        ChangeCustomer: "Change customer",
        NoCustomersFound: "No customers found",
        ContactPerson: "Contact Person",
        AddCustomer: "Add Customer",

        // Storages
        Wheel: "Wheel",
        Wheels: "Wheels",
        Storage: "Wheel Storage",
        Storages: "Storages",
        StorageNumber: "Number",
        StorageNumberLong: "Storage Number",
        StorageLocation: "Location",
        StorageType: "Type",
        StorageBearing: "Bearing",
        TyreTread: "Tyre Tread Pattern",
        ProfileDepth: "Profiledepth",
        ProfileDepthMin: "Profiledepth Min.",
        ProfileDepthOuter: "Profiledepth outer",
        ProfileDepthMiddle: "Profiledepth middle",
        ProfileDepthInner: "Profiledepth inner",
        Outer: "outer",
        Middle: "middle",
        Inner: "inner",
        Sensor: "Sensor",
        AirPressure: "Air Pressure",
        RimType: "Rim Type",
        TyreAge: "Tyre Age",
        TyreDiameter: "Tyre Diameter",
        TyreManufacturer: "Tyre Manufacturer",
        Diamater: "Diamater",
        RDKS: "RDKS",
        RDKSNone: "None",
        RDKSActive: "Active",
        RDKSPassive: "Passiv",
        StorageDuration: "Storage Duration",
        StorageActive: "Stored",
        StorageInactive: "Evaucated",
        CheckStatus: "To Check",
        InWorkStatus: "In Process",
        DoneStatus: "Done",
        Steel: "Steel",
        Alu: "Alu",
        UnknownState: "Unknown",
        OkayState: "Okay",
        MarginalState: "Marginal",
        RiskyState: "Risky",
        StorageDate: "Storage Date",
        SwapDate: "Swap Date",
        ContractUntil: "Contract Until",
        TyreAndRimState: "Tyre and Rim State",
        TakeOverFromFront: "Take over from the front",
        TakeOverFromRear: "Take over from the rear",
        Productgroup: "Productgroup",
        Width: "Width",
        Height: "Height",
        Length: "Length",
        Inches: "Inches",
        LoadIndex: "LoadIdx",
        SpeedIndex: "SpeedIdx",
        RollingFriction: "Rollingffriction",
        WetGrip: "Wetgrip",
        NoiseDb: "Noise (Db)",
        Manufacturer: "Manufacturer",
        Profile: "Profile",
        TyreSearch: "Tyresearch",
        RimSearch: "Rimsearch",
        StorageImage: "Image",
        AddStorage: "Add Storage",
        NoStoragesFound: "No storages found",
        SelectManufacturerFirst: "Select manufacturer first",
        NumberOfTyres: "Number of Tyres",
        PickupDays: "Pickup Period",
        TimePeriod: "Time Period",
        Device: "Device",
        Measurements: "Measurements",
        NoMeasurementsFound: "No measurements found.",
        DiagnoseType: "Diagnose Type",
        EntryDiagnose: "Entry Diagnose",
        ExitDiagnose: "Exit Diagnose",
        "30MIN": "30 Minutes",
        "3Days": "3 Days",
        "7Days": "7 Days",
        MeasurementsMatchingStorage: "Measurements matching storage",
        MeasurementsNotMatchingStorage: "Measurements not matching storage",
        "MeasurementWheel.1": "Front left",
        "MeasurementWheel.2": "Front right",
        "MeasurementWheel.3": "Rear right",
        "MeasurementWheel.5": "Rear left",
        AdoptValuesIntoStorage: "Adopt values into storage",
        MeasurementAdopted: "Measurement adopted",
        MeasurementAdoptedMessage: "Save wheel storage?",

        UpdateFrontAndRearTyreHeader: "Question",
        UpdateFrontAndRearTyreMessage: "Take over tyre for the front and rear?",

        StorageAlreadyExistsHeader: "Error",
        StorageAlreadyExistsMessage:
            "A wheel storage with the same number and type already exists.",

        "storage.active.true": "Active",
        "storage.active.false": "Not active",
        "storage.type.SUMMER": "Summer",
        "storage.type.WINTER": "Winter",
        "storage.type.ALLSEASON": "Allseason",
        "storage.state.OKAY": "Okay",
        "storage.state.MARGINAL": "Marginal",
        "storage.state.RISKY": "Risky",
        "storage.state.UNKNOWN": "Unknown",
        "storage.status.DONE": "Done",
        "storage.status.INWORK": "In Work",
        "storage.status.CHECK": "To Check",
        "rim.type.ALU": "Alu",
        "rim.type.STEEL": "Steel",
        "rim.type.ALLSEASON": "Allseason",
        "rim.type.NONE": "None",
        "wheel.position.FRONT_LEFT": "Front Left",
        "wheel.position.FRONT_RIGHT": "Front Right",
        "wheel.position.REAR_LEFT": "Rear Left",
        "wheel.position.REAR_RIGHT": "Rear Right",
        "wheel.position.SPARE": "Spare",

        ChartStorageTypeTitle: "Type",
        ChartStorageActiveTitle: "Active",
        ChartStorageStateTitle: "State",
        ChartStorageStatusTitle: "Status",
        ChartStorageProfileDepthTile: "Profile Depths (mm)",
        ChartStorageTyreManufacturerTitle: "Tyre Manufacturer",
        ChartStorageTyreDiameterTitle: "Tyre Diameter",
        ChartStorageOverdueTitle: "Overdue",

        "chart.storage.active.series.name": "Storages",
        "chart.storage.tyre.diameter.series.name": "Tyres",
        "chart.storage.tyre.manufacturer.series.name": "Tyres",
        "chart.storage.tyre.profile.depth.series.name": "Tyres",
        "chart.storage.overdue.series.name": "Storages",
        "chart.storage.state.series.name": "Storages",
        "chart.storage.status.series.name": "Storages",

        // Vehicles
        VehicleRegistration: "Vehicle Registration",
        FleetsCard: "Fleets Card",
        TagNumber: "Tag Number",
        VehicleType: "Vehicle Type",
        SearchVehicleType: "Search Vehicle Type",
        HSNTSN: "HSN/TSN",
        HSN: "HSN",
        TSN: "TSN",
        VehicleFirstRegistration: "Registration",
        VIN: "VIN",
        Inspections: "Inspections",
        Mileage: "Mileage",
        HU: "HU",
        LastInspection: "Last Inspection",
        LastAirConditionInspection: "Last air condition",
        LeasingAndFleet: "Leasing / Fleet",
        LeasingVehicle: "Leasing Vehicle",
        LeasingNumber: "Leasing Number",
        FleetsVehicle: "Fleets Vehicle",
        FleetsNumber: "Fleets Number",
        Driver: "Driver",
        DriverHasBirthday: "Driver has birthday",
        HURequiredAt: "HU required",
        RequiredAt: "Required at",
        Vehicles: "Vehicles",
        NewVehicle: "New vehicle",
        ExistingVehicle: "Existing Vehicle",
        VehicleSearch: "Search vehicle",
        ChangeVehicle: "Change vehicle",
        NoVehiclesFound: "No vehicles found",
        BuildYear: "Build year",
        EngineCapacity: "Engine",
        FirstAidkitExpired: "First aid kit expired",
        FirstAidkitExpiry: "First aid kit expiry",
        EnginePowerKW: "Leistung (kW)",
        EngingCapacity: "Capacity",
        MaxSpeed: "Max. Speed",
        FuelType: "Fuel Type",
        EmptyWeight: "Empty Weight",
        NameOrCompany: "Name oder Firma",
        AddVehicle: "Add Vehicle",

        ScanVehicleTag: "Scan vehicle tag",
        ScanningForVehicleTag: "Scanning for vehicle tag",
        VehicleTagNotFound: "No vehicle tag found.",

        ScanVehicleRegistration: "Scan vehicle registration",
        ScanningForVehicleRegistration: "Scanning for vehicle registration",
        VehicleRegistrationNotFound: "No vehicle registration found",
        TakeOverVehicleRegistration: "Take over vehicle registration",

        ScanMileage: "Scan mileage",
        ScanningForMileage: "Scanning for mileage",
        MileageNotFound: "No mileage found.",

        VehicleAlreadyExistsHeader: "Error",
        VehicleAlreadyExistsMessage: "Vehicle already exists.",

        NoDataFoundForVehicleTag: "No data found for the vehicle tag.",

        // Bookings
        Services: "Services",
        Progress: "Progress",
        Ressource: "Ressource",
        VehicleDelivery: "Vehicle Delivery",
        VehiclePickup: "Vehicle Pickup",
        Processor: "Processor",
        Description: "Description",
        NoBookingsFound: "No bookings found",
        HasStorage: "Has storage",
        Service: "Service",
        Resource: "Resource",
        TodayBookings: "Bookings from Today",
        PastBookings: "Past Bookings",
        FutureBookings: "Future Bookings",

        "booking.state.open": " Open",
        "booking.state.finished": "Finished",
        "booking.state.cancelled": "Cancelled",
        "booking.state.proposed": "Proposed",
        "booking.state.rejected": "Rejected",

        "booking.progress.WAITING": " Waiting for Vehicle",
        "booking.progress.DELIVERED": "Vehicle Delivered",
        "booking.progress.PROCESSING": "Processing",
        "booking.progress.FINISHED": "Finished",
        "booking.progress.PICKEDUP": "Pickedup",

        // Images
        DeleteImageHeader: "Delete Image",
        DeleteImageMessage: "Do you want to permanently delete the image?",
        NoImagesFound: "No images found",

        "image.damage": "Damage",
        "image.front": "Front",
        "image.back": "Back",
        "image.vehicle": "Vehicle",
        "image.vehicle.registration": "Vehicle Registration",
        "image.vehicle.leasingcard": "Leasing Card",
        "image.vehicle.fleetcard": "Fleet Card",
        "image.storage": "Storage",
        "image.storage.tyre": "Tyre",
        "image.storage.tyre.fl": "Tyre (FL)",
        "image.storage.tyre.fr": "Tyre (FR)",
        "image.storage.tyre.rl": "Tyre (HL)",
        "image.storage.tyre.rr": "Tyre (HR)",
        "image.storage.rim": "Rim",
        "image.storage.rim.fl": "Rim (FL)",
        "image.storage.rim.fr": "Rim (FR)",
        "image.storage.rim.rl": "Rim (HL)",
        "image.storage.rim.rr": "Rim (HR)",

        // Voucher
        Order: "Order",
        Voucher: "Voucher",
        Vouchers: "Vouchers",
        VoucherType: "Type",
        VoucherItem: "Position",
        VoucherItems: "Items",
        NetPrice: "Net",
        GrossPrice: "Gross",
        SalesPrice: "Sales Price",
        Seller: "Seller",
        LastEditDate: "Last Change",
        Quantity: "Quantity",
        VatRate: "VAT",
        NoVouchersFound: "No vouchers found",

        DeleteVoucherItemHeader: "Delete",
        DeleteVoucherItemMessage: "Do you want to delete the item?",
        OverwritePriceHeader: "Overwrite Price",
        OverwritePriceMessage: "Do you want to overwrite the price?",

        "voucher.type.EL": "Storage Voucher",
        "voucher.type.AN": "Offer",
        "voucher.type.RS": "Allocation",
        "voucher.type.KB": "Customer Order",
        "voucher.type.MO": "Assembly Voucher",
        "voucher.type.LS": "Delivery Note",
        "voucher.type.GU": "Credit Advice",
        "voucher.type.WG": "Value Advice",
        "voucher.type.AR": "Invoice",

        // Chat
        AIFunctions: "AI-Functions (Beta)",
        ChatWelcome: "Welcome!",
        ChatIntro: "How can I assist you today?",
        ChatDetails: "You can ask me questions about your customers, vehicles, storages and appointments?",
        YourQuestion: "Your Question",
        ClearChat: "Clear Chat",
        "message.user": "User",
        "message.system": "AI",
        WheelInformations: "Wheel Informations",
        EditWheelInformationsIntro: "Edit Wheel Informations",
        EditTyreInformationsIntro:
            "Please let me know which wheel data you want to change. For example, tread depth front left 3mm",
        "ApplyWheelInformations": "Apply Wheel Informations",
        ForExample: "For Example",
    },
};
