import { NgModule } from "@angular/core";
import { TagModule } from "@app/components/tag";
import { SharedModule } from "@app/shared.module";
import { ImageViewerPageModule } from "./../image-viewer/image-viewer.module";
import { ImageDetailsPage } from "./image-details.page";

@NgModule({
    imports: [SharedModule, TagModule, ImageViewerPageModule],
    exports: [ImageDetailsPage],
    declarations: [ImageDetailsPage]
})
export class ImageDetailsPageModule {}
