import { Injectable } from "@angular/core";
import { LocalizedAlertController } from "@tcn/ion-common";

export interface AlertOptions {
    header?: string;
    message?: string;
}

@Injectable({ providedIn: "root" })
export class Alerts {
    constructor(public ctrl: LocalizedAlertController) {}

    async showSaveError(
        options: AlertOptions = {
            header: "SaveErrorHeader",
            message: "SaveErrorMessage",
        }
    ) {
        const alert = await this.ctrl.create({
            header: options.header,
            message: options.message,
            buttons: [
                {
                    text: "Ok",
                },
            ],
        });

        await alert.present();
    }

    async showCommonError(
        options: AlertOptions = {
            header: "CommonErrorHeader",
            message: "CommonErrorMessage",
        }
    ) {
        const alert = await this.ctrl.create({
            header: options.header,
            message: options.message,
            buttons: [
                {
                    text: "Ok",
                },
            ],
        });

        await alert.present();
    }

    showCancelConfirmation(
        options: AlertOptions = {
            header: "CancelConfirmationHeader",
            message: "CancelConfirmationMessage",
        }
    ) {
        return this.showConfirmation(options);
    }

    showConfirmation(options: AlertOptions) {
        return new Promise((resolve, reject) => {
            this.ctrl
                .create({
                    backdropDismiss: false,
                    header: options.header,
                    message: options.message,
                    buttons: [
                        {
                            text: "Yes",
                            role: "cancel",
                            cssClass: "secondary",
                            handler: () => resolve(true),
                        },
                        {
                            text: "No",
                            handler: () => resolve(false),
                        },
                    ],
                })
                .then((alert) => {
                    alert.present();
                });
        });
    }
}
