import { CustomerQuery, VehicleQuery } from "@yps/neonis-api-ngx";
import { Entity } from "../../../models/entity.model";

export class VehicleFilter extends Entity {
    name: string;
    query: VehicleQuery;

    constructor(filter?: Partial<VehicleFilter>) {
        super(filter);
        this.assign(filter);
    }

    assign(filter: Partial<VehicleFilter>): VehicleFilter {
        if (filter) {
            Object.assign(this, filter);
            this.query = new VehicleQuery(filter.query);
        }

        this.query = this.query || new VehicleQuery();

        return this;
    }

    get empty(): boolean {
        return !this.query || this.query.empty;
    }

    equals(filter: Partial<VehicleFilter>): boolean {
        if (!filter || !filter.query) {
            return false;
        }

        return this.query.equals(filter.query) && this.name === filter.name;
    }
}
