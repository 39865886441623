import { Reduce, StoreAction } from "@tcn/ngx-redux-store-config";
import { TagsService, Tag, TagsQuery, Entity } from "@yps/neonis-api-ngx";
import { Injectable } from "@angular/core";
import { AppState } from "../app-state";
import { INITIAL_STATE } from "../initial-state";
import { Action } from "@tcn/ngx-redux-store-config";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable()
export class TagsApi {
    constructor(private tagsService: TagsService) {}

    @Action(null, { cache: { maxAge: 30 * 1000, return: "tags" } })
    loadTags(): Observable<Tag[]> {
        return this.tagsService
            .queryTags(new TagsQuery())
            .pipe(map((response) => response.data.map((tag) => new Tag(tag))));
    }

    @Action()
    addTag(tag: Tag): Observable<Tag> {
        return this.tagsService.saveTag(tag).pipe(map((tag) => new Tag(tag)));
    }

    @Action()
    saveTag(tag: Tag): Observable<Tag> {
        return this.tagsService.saveTag(tag).pipe(map((tag) => new Tag(tag)));
    }

    @Reduce()
    private _loadTags(
        state: AppState = INITIAL_STATE,
        action: StoreAction
    ): AppState {
        return { ...state, tags: [...action.payload] };
    }

    @Reduce()
    private _addTag(
        state: AppState = INITIAL_STATE,
        action: StoreAction
    ): AppState {
        return { ...state, tags: [...state.tags, action.payload] };
    }

    @Reduce()
    private _saveTag(
        state: AppState = INITIAL_STATE,
        action: StoreAction
    ): AppState {
        return {
            ...state,
            tags: Entity.replaceInList(action.payload, state.tags),
        };
    }
}
