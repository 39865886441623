import { Injectable } from "@angular/core";
import { Action, Reduce } from "@tcn/ngx-redux-store-config";
import { Settings } from "../../models";
import { AppState } from "../app-state";
import { INITIAL_STATE } from "../initial-state";
import { LocalStorage } from "@app/services/local-storage.service";

@Injectable()
export class SettingsApi {
    constructor(private storage: LocalStorage) {}

    /*
     * App Settings
     */

    @Action()
    loadSettings = (): Promise<Settings> => {
        return this.storage.get("settings").then((settings) => {
            return new Settings(JSON.parse(settings));
        });
    };

    @Action()
    saveSettings = (settings: Partial<Settings>): Promise<Settings> => {
        return this.storage
            .set("settings", JSON.stringify(settings))
            .then(() => new Settings(settings));
    };

    @Reduce("loadSettings")
    protected loadSettingsReducer(state = INITIAL_STATE, action): AppState {
        return {
            ...state,
            settings: new Settings(state.settings, action.payload),
        };
    }

    @Reduce("saveSettings")
    protected saveSettingsReducer(state = INITIAL_STATE, action): AppState {
        return {
            ...state,
            settings: new Settings(state.settings, action.payload),
        };
    }
}
