import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";
import { TranslateService } from "@ngx-translate/core";
import { ApiConfigService } from "@tcn/ngx-common";
import { DocumentsService, Image } from "@yps/neonis-api-ngx";
import { decode } from "base64-arraybuffer";
import { forkJoin, Observable, of, ReplaySubject } from "rxjs";
import { flatMap, map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class ImageService {
    constructor(
        protected http: HttpClient,
        protected apiConfig: ApiConfigService,
        protected docService: DocumentsService,
        private translate: TranslateService
    ) {}

    saveImage(image: Image): Observable<Image> {
        return this.uploadContent(image).pipe(
            flatMap((image) => this.docService.saveDocument(image)),
            map((doc) => new Image(doc))
        );
    }

    uploadContent(image: Image): Observable<Image> {
        if (!image.content) {
            return of(image);
        }

        let content = image.content;
        if (typeof content === "string") {
            content = decode(image.content);
        }

        const apiConfig = this.apiConfig.config("webdav.api.v1");
        const davUrl = "webdav.api.images.v1/" + image.path;

        image = new Image({
            ...image,
            src: apiConfig.parseUrl(davUrl),
            content: null,
        });

        if (!image.meta.sizes.original) {
            image.meta.sizes.original = image.src;
        }

        return this.http.put<Blob>(davUrl, content).pipe(map(() => image));
    }

    deleteImage(image: Image): Observable<any> {
        return this.deleteContent(image).pipe(
            flatMap((image) => this.docService.deleteDocument(image.id))
        );
    }

    deleteContent(image: Image): Observable<Image> {
        const sizes = image.sizes;
        if (sizes.length === 0) {
            return of(image);
        }

        const deletes$ = sizes.map((img) => {
            const apiConfig = this.apiConfig.config("webdav.api.v1");
            const davUrl = apiConfig.parseUrl(image.src);
            return this.http.delete(davUrl).pipe(map(() => image));
        });

        return forkJoin(deletes$).pipe(map(() => image));
    }

    takeImage(image?: Image, header = "TakeImage"): Observable<Image> {
        const subject = new ReplaySubject<Image>(1);
        image = new Image(image);

        this.translate
            .get(["TakeImage", "Cancel", "Camera", "Gallery"])
            .pipe(
                map((translations) => {
                    const options = {
                        quality: 90,
                        allowEditing: false,
                        saveToGallery: false,
                        resultType: CameraResultType.Uri,
                        source: CameraSource.Prompt, // Use prompt to ask user for source
                        promptLabelHeader: translations[header],
                        promptLabelCancel: translations["Cancel"],
                        promptLabelPhoto: translations["Gallery"],
                        promptLabelPicture: translations["Camera"],
                    };

                    Camera.getPhoto(options)
                        .then((photo) => {
                            image.mimeType = "image/" + photo.format;
                            image.src = photo.webPath;

                            // Fetch the image content as a Blob
                            return fetch(photo.webPath).then((r) => r.blob());
                        })
                        .then((content) => {
                            image.content = content;
                            subject.next(image);
                        })
                        .catch((error) => {
                            if (
                                error.message.includes(
                                    "User cancelled photos app"
                                )
                            ) {
                                subject.complete();
                            } else {
                                subject.error(error);
                            }
                        })
                        .finally(() => subject.complete());
                })
            )
            .subscribe();

        return subject.asObservable();
    }
}
