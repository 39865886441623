import { registerLocaleData } from "@angular/common";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import localeDe from "@angular/common/locales/de";
import localeEn from "@angular/common/locales/en";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy } from "@angular/router";
import { CategorySelectionPage } from "@app/modals/category-selection/category-selection.page";
import { ImageDetailsPageModule } from "@app/modals/image-details";
import { TextEditorPage } from "@app/modals/text-editor/text-editor.page";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import {
    LocalizedActionSheetController,
    LocalizedAlertController,
} from "@tcn/ion-common";
import { NeonisApiInterceptor } from "@yps/neonis-api-ngx";
import * as moment from "moment";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ProparApiInterceptor } from "./interceptors/propar-api.interceptor";
import { WebdavApiInterceptor } from "./interceptors/webdav-api.interceptor";
import { CategorySelectionPageModule } from "./modals/category-selection/category-selection.module";
import { ImageDetailsPage } from "./modals/image-details/image-details.page";
import { ImagesFilterPageModule } from "./modals/images-filter/images-filter.module";
import { ImagesFilterPage } from "./modals/images-filter/images-filter.page";
import { PositionSelectionPageModule } from "./modals/position-selection/position-selection.module";
import { PositionSelectionPage } from "./modals/position-selection/position-selection.page";
import { TagSelectionPageModule } from "./modals/tag-selection/tag-selection.module";
import { TagSelectionPage } from "./modals/tag-selection/tag-selection.page";
import { TextEditorPageModule } from "./modals/text-editor/text-editor.module";
import { StoreModule } from "./store/module";
import { LoadingInterceptor } from "./interceptors/loading.interceptor";
import { IonicStorageModule } from "@ionic/storage-angular";
import { Drivers } from "@ionic/storage";

registerLocaleData(localeEn, "en");
registerLocaleData(localeDe, "de");

/*
 * Overwrite date toISOString to always use the local date
 * when serializing dates to strings.
 */

Date.prototype.toISOString = function () {
    return moment(this).format("YYYY-MM-DDTHH:mm:ss");
};

//momentDurationFormatSetup(moment);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,

        IonicModule.forRoot({
            mode: "ios",
            hardwareBackButton: false,
            swipeBackEnabled: false,
        }),

        AppRoutingModule,
        TranslateModule.forRoot(),

        IonicStorageModule.forRoot({
            driverOrder: [Drivers.LocalStorage],
        }),

        StoreModule,

        // Modals
        ImagesFilterPageModule,
        CategorySelectionPageModule,
        PositionSelectionPageModule,
        TagSelectionPageModule,
        TextEditorPageModule,
        ImageDetailsPageModule,
    ],
    providers: [
        LocalizedAlertController,
        LocalizedActionSheetController,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: NeonisApiInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ProparApiInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: WebdavApiInterceptor,
            multi: true,
        },
    ],
    entryComponents: [
        // Modals
        ImagesFilterPage,
        CategorySelectionPage,
        PositionSelectionPage,
        TagSelectionPage,
        TextEditorPage,
        ImageDetailsPage,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
