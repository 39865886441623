import { Reduce, StoreAction } from "@tcn/ngx-redux-store-config";
import {
    TagsService,
    Tag,
    TagsQuery,
    Entity,
    ConfigService,
    Service,
    Resource,
} from "@yps/neonis-api-ngx";
import { Injectable } from "@angular/core";
import { AppState } from "../app-state";
import { INITIAL_STATE } from "../initial-state";
import { Action } from "@tcn/ngx-redux-store-config";
import { map } from "rxjs/operators";
import { Observable } from "rxjs";

@Injectable()
export class ConfigApi {
    constructor(private configService: ConfigService) {}

    @Action(null, { cache: { maxAge: 30 * 1000, return: "services" } })
    loadServices(): Observable<Service[]> {
        return this.configService
            .getServices()
            .pipe(
                map((response) =>
                    response.map((service) => new Service(service))
                )
            );
    }

    @Action(null, { cache: { maxAge: 30 * 1000, return: "resources" } })
    loadResources(): Observable<Resource[]> {
        return this.configService
            .getResources()
            .pipe(
                map((response) =>
                    response.map((resource) => new Resource(resource))
                )
            );
    }

    @Reduce()
    private _loadServices(
        state: AppState = INITIAL_STATE,
        action: StoreAction
    ): AppState {
        return { ...state, services: [...action.payload] };
    }

    @Reduce()
    private _loadResources(
        state: AppState = INITIAL_STATE,
        action: StoreAction
    ): AppState {
        return { ...state, resources: [...action.payload] };
    }
}
