import { UUID } from "../utils/UUID";

export class Entity {
    id: string;
    version: number;

    static findInList<T extends Entity>(entity: T, list: Array<T>): T {
        if (!entity || !list) {
            return null;
        }

        return list.find($ => $.id === entity.id);
    }

    static removeFromList<T extends Entity>(
        entity: T,
        list: Array<T>
    ): Array<T> {
        if (!entity || !list) {
            return list;
        }

        return list.filter($ => $.id !== entity.id);
    }

    static replaceInList<T extends Entity>(
        entity: T,
        list: Array<T>
    ): Array<T> {
        if (!entity || !list) {
            return list;
        }

        return list.map($ => ($.id === entity.id ? entity : $));
    }

    static replaceOrAddInList<T extends Entity>(
        entity: T,
        list: Array<T>
    ): Array<T> {
        if (Entity.existsInList(entity, list)) {
            return Entity.replaceInList(entity, list);
        } else {
            return [...list, entity];
        }
    }

    static existsInList<T extends Entity>(entity: T, list: Array<T>): boolean {
        if (!entity || !list) {
            return false;
        }

        return list.find($ => $.id === entity.id) != null;
    }

    constructor(entity?: Partial<Entity>) {
        this.id = UUID.new();

        if (entity) {
            Object.assign(this, entity);
        }
    }

    public equals(other: Entity): boolean {
        return other != null && other.id === this.id;
    }

    public get persisted(): boolean {
        return !!this.version;
    }
}
