import { Injectable, NgZone } from "@angular/core";
import { ReplaySubject } from "rxjs";
import { Theme } from "./../models/settings.model";

@Injectable({
    providedIn: "root",
})
export class ViewModeService {
    private prefersDark: MediaQueryList;
    private _darkMode$ = new ReplaySubject<boolean>(1);
    private _theme$ = new ReplaySubject<Theme>(1);

    constructor(private zone: NgZone /*, private statusBar: StatusBar */) {
        this.prefersDark = window.matchMedia("(prefers-color-scheme: dark)");

        this.prefersDark.addListener((mediaQuery) => {
            this.zone.run(() => {
                this._darkMode$.next(mediaQuery.matches);
            });
        });
    }

    get darkMode$() {
        return this._darkMode$.asObservable();
    }

    get theme$() {
        return this._theme$.asObservable();
    }

    toggleDarkMode(enabled) {
        document.body.classList.toggle("dark", enabled);

        if (enabled) {
            // this.statusBar.styleBlackTranslucent();
        } else {
            // this.statusBar.styleDefault();
        }

        this._theme$.next(enabled ? "dark" : "light");
    }

    isDarkModeEnabled() {
        return window.matchMedia("(prefers-color-scheme: dark)").matches;
    }
}
