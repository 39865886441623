export const locale = {
    lang: "de-DE",
    formats: {
        date: "DD.MM.YYYY",
        time: "HH:mm",
        dateTime: "DD.MM.YYYY HH:mm",
    },
    data: {
        Signin: "Anmelden",
        Signout: "Abmelden",
        Tenant: "Mandant",
        Username: "Benutzername",
        Password: "Passwort",
        InvalidCredentialsMessage: "Ungültige Zugangsdaten",

        Settings: "Einstellungen",
        Filter: "Filter",
        Saved: "Gespeicherte",
        Sorting: "Sortierung",
        SortBy: "Sortieren nach",
        SortOrder: "Reihenfolge",
        Ascend: "Aufsteigend",
        Descend: "Absteigend",

        Status: "Status",
        Rim: "Felge",
        Condition: "Zustand",
        Tags: "Tags",
        AddTag: "Tag Hinzufügen",
        Options: "Optionen",
        Number: "Nummer",
        Summer: "Sommer",
        SUMMER: "Sommer",
        Winter: "Winter",
        WINTER: "Winter",
        AllSeason: "Ganzjahr",
        ALLSEASON: "Ganzjahr",
        Year: "Jahr",
        Years: "Jahre",
        Age: "Alter",
        Overdue: "Überfällig",
        NotOverdue: "Nicht Überfällig",
        Name: "Name",
        All: "Alle",
        Mark: "Markierung",
        Marked: "Markiert",
        PickedUp: "Abgeholt",
        NotPickedUp: "Nicht abgeholt",
        Search: "Suche",
        Common: "Allgemein",
        Image: "Bild",
        Images: "Bilder",
        Materials: "Materialien",
        HubCaps: "Radkappen",
        RimLocks: "Schlösser",
        WheelNuts: "Radmuttern",
        Stored: "Eingelagert",
        NotStored: "Ausgelagert",
        AtCustomerSite: "Beim Kunden",
        Tyres: "Reifen",
        Rims: "Felgen",
        Front: "Vorne",
        Rear: "Hinten",
        FrontLeft: "Vorne Links",
        FrontRight: "Vorne Rechts",
        RearLeft: "Hinten Links",
        RearRight: "Hinten Rechts",
        Customer: "Kunde",
        Vehicle: "Fahrzeug",
        Booking: "Termin",
        Bookings: "Termine",
        NextBooking: "Nächster Termin",
        Company: "Firma",
        Mr: "Herr",
        Mrs: "Frau",
        Address: "Adresse",
        Birthday: "Geburtstag",
        ContactInfo: "Kontakt",
        Phone: "Telefon",
        MobileNumber: "Mobil",
        Email: "E-Mail",
        Note: "Notiz",
        Today: "Heute",
        Week: "Woche",
        Month: "Monat",
        Tomorrow: "Morgen",
        OClock: "Uhr",
        Designation: "Bezeichnung",
        NewImage: "Neues Bild",
        Type: "Typ",
        Street: "Straße",
        Zipcode: "Postleitzahl",
        City: "Stadt",
        Housenumber: "Hausnummer",
        Category: "Kategorie",
        CreatedOn: "Erstellt am",
        CreateBy: "Erstellt von",
        UpdatedOn: "Aktualisiert am",
        UpdatedBy: "Aktualisiert von",
        Branch: "Filiale",
        Count: "Anzahl",
        FrontSide: "Vorderseite",
        BackSide: "Rückseite",
        Automatically: "Automatisch",
        Without: "Ohne",
        Actions: "Aktionen",
        Months: "Monate",
        till: "bis",
        Diameter: "Durchmesser",
        Total: "Gesamt",
        Salutation: "Anrede",
        Title: "Titel",
        Camera: "Kamera",
        Gallery: "Gallerie",
        Send: "Senden",
        Theme: "Ansicht",
        Dark: "Dunkel",
        Light: "Hell",
        Standard: "Standard",
        Enabled: "Aktiviert",
        Color: "Farbe",
        ColorCode: "Farbcode",
        TakeOverData: "Daten übernehmen",
        TakeOverMileage: "Kilometerstand übernehmen",
        Reports: "Auswertungen",
        Available: "Vorhanden",
        Date: "Datum",
        State: "Status",
        CustomerNumber: "Kundennummer",
        CustomerName: "Kundenname",
        WheelStorageNumber: "Einlagerungsnummer",
        Location: "Ort",

        // Common actions
        Yes: "Ja",
        No: "Nein",
        Cancel: "Abbrechen",
        Back: "Zurück",
        Ok: "Ok",
        Start: "Start",
        Stop: "Stop",
        Finish: "Abschließen",
        Finished: "Fertig",
        Add: "Hinzufügen",
        Delete: "Löschen",
        Remove: "Entfernen",
        Save: "Speichern",
        Close: "Schließen",
        Apply: "Übernehmen",
        Reset: "Zurücksetzen",
        Rename: "Umbenennen",
        Edit: "Bearbeiten",
        AddMark: "Markieren",
        TakeImage: "Bild hinzufügen",
        Update: "Aktualisieren",
        Refresh: "Aktualisieren",
        Next: "Weiter",
        View: "Anzeigen",
        ViewAll: "Alle anzeigen",
        ShowMessages: "Hinweise anzeigen",
        HideMessages: "Hinweise ausblenden",

        // Tab titles
        HomeTabTitle: "Home",
        StoragesTabTitle: "Einlagerungen",
        ReportsTabTitle: "Auswertungen",
        SettingsTabTitle: "Einstellungen",
        InfoTabTitle: "Info",

        // Page titles
        HomePageTitle: "Home",
        SettingsPageTitle: "Einstellungen",
        StorageListPageTitle: "Einlagerungen",
        StorageDetailsPageTitle: "Einlagerung",
        StorageReportsPageTitle: "Auswertung/Einlagerung",
        InfoPageTitle: "Info",
        ContactPageTitle: "Kontakt",
        VehicleRegistrationPageTitle: "Fahrzeugschein",
        FleetCardPageTitle: "Flottenkarte",
        SearchPageTitle: "Suche",
        AddStoragePageTitle: "Neue Einlagerung",
        VehicleTypeSearchPageTitle: "Fahrzeugsuche",
        VehicleScanPageTitle: "Fahrzeug-Scan",
        VehicleListPageTitle: "Fahrzeuge",
        VehicleDetailsPageTitle: "Fahrzeug",
        BookingListPageTitle: "Termine",
        BookingDetailsPageTitle: "Termin",
        CustomerListPageTitle: "Kunden",
        CustomergDetailsPageTitle: "Kunde",
        ChatPageTitle: "Chat",
        AddVehiclePageTitle: "Neues Fahrzeug",
        AddCustomerPageTitle: "Neuer Kunde",

        // Alerts
        SigninErrorHeader: "Fehler",
        SigninErrorMessage:
            "Bei der Anmeldung ist ein Fehler aufgetreten. Bitte prüfen Sie Ihre Daten und versuchen es erneut.",

        SignoutHeader: "Abmelden",
        SignoutMessage: "Möchten Sie sich abmelden?",

        SaveErrorHeader: "Fehler",
        SaveErrorMessage:
            "Es ist ein Fehler beim Speichern aufgetreten. Bitte versuchen Sie es später erneut.",

        CommonErrorHeader: "Fehler",
        CommonErrorMessage:
            "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",

        CancelConfirmationHeader: "Abbrechen",
        CancelConfirmationMessage: "Möchten Sie wirklich abbrechen?",

        ClearFilterHeader: "Alle anzeigen",
        ClearFilterMessage: "Möchten Sie den Filter zurücksetzen?",

        // ContactPage
        InterestedIn: "Interessiert an...",
        Bug: "Fehler",
        SuggestionForImprovement: "Verbesserungsvorschlag",
        Question: "Frage",
        Other: "Sonstiges",
        Message: "Nachricht",
        ContactTitle: "Fragen, Wünsche oder Kritik?",
        ContactSubtitle: "Wir freuen uns von Dir zu hören.",
        ContactSuccessHeader: "Erfolgreich",
        ContactSuccessMessage:
            "Wir haben deine Nachricht erfolgreich erhalten. Vielen Dank für dein Interesse.",

        // Settings page
        Language: "Sprache",
        German: "Deutsch",
        English: "Englisch",
        DarkMode: "Dunkle Ansicht",
        ShowNotesByDefault: "Hinweise standardmäßig anzeigen",
        User: "Benutzer",

        // Info page
        RateApp: "App Bewerten",
        Contact: "Kontakt",
        VersionInfo: "Versions-Info",
        Legal: "Rechtliches",
        BusinessTerms: "AGB",
        LegalNotice: "Impressum",
        PrivacyPolicy: "Datenschutz",

        // Customers
        Customers: "Kunden",
        CustomerHasBirthday: "Kunde hat Geburtstag",
        NewCustomer: "Neuer Kunde",
        ExistingCustomer: "Bestandskunde",
        CustomerSearch: "Kundensuche",
        ChangeCustomer: "Kunde ändern",
        NoCustomersFound: "Keine Kunden gefunden",
        ContactPerson: "Ansprechpartner",
        AddCustomer: "Kunde Hinzufügen",

        // Storages
        Wheel: "Rad",
        Wheels: "Räder",
        Storage: "Einlagerung",
        Storages: "Einlagerungen",
        StorageNumber: "Nummer",
        StorageNumberLong: "Einlagerungsnummer",
        StorageType: "Typ",
        StorageLocation: "Lagerort",
        StorageBearing: "Lagerung",
        TyreTread: "Laufbild",
        ProfileDepth: "Profiltiefe",
        ProfileDepthMin: "Profiltiefe Min.",
        ProfileDepthOuter: "Profiltiefe außen",
        ProfileDepthMiddle: "Profiltiefe mitte",
        ProfileDepthInner: "Profiltiefe innen",
        Outer: "außen",
        Middle: "mitte",
        Inner: "innen",
        Sensor: "Sensor",
        AirPressure: "Luftdruck",
        RimType: "Felgentyp",
        TyreAge: "Reifenalter",
        TyreDiameter: "Reifengröße",
        TyreManufacturer: "Reifenhersteller",
        Diamater: "Durchmesser",
        RDKS: "RDKS",
        RDKSNone: "Ohne",
        RDKSActive: "Aktiv",
        RDKSPassive: "Passiv",
        StorageDuration: "Laufzeit",
        StorageActive: "Eingelagert",
        StorageInactive: "Ausgelagert",
        CheckStatus: "Zu prüfen",
        InWorkStatus: "In Bearbeitung",
        DoneStatus: "Fertig",
        Steel: "Stahl",
        Alu: "Alu",
        UnknownState: "Unbekannt",
        OkayState: "Gut",
        MarginalState: "Grenzwertig",
        RiskyState: "Gefährlich",
        StorageDate: "Eingelagert am",
        SwapDate: "Ausgelagert am",
        ContractUntil: "Vertrag bis",
        TyreAndRimState: "Zustand Reifen & Felgen",
        TakeOverFromFront: "Von Vorne übernehmen",
        TakeOverFromRear: "Von Hinten übernehmen",
        Productgroup: "Warengruppe",
        Width: "Breite",
        Height: "Höhe",
        Length: "Lange",
        Inches: "Zoll",
        LoadIndex: "LoadIdx",
        SpeedIndex: "SpeedIdx",
        RollingFriction: "Rollwiederstand",
        WetGrip: "Nasshaftung",
        NoiseDb: "Rollgeräusch (Db)",
        Manufacturer: "Hersteller",
        Profile: "Profil",
        TyreSearch: "Reifensuche",
        RimSearch: "Felgensuche",
        StorageImage: "Einlagerungsbild",
        AddStorage: "Neue Einlagerung",
        NoStoragesFound: "Keine Einlagerungen gefunden",
        SelectManufacturerFirst: "Erst Hersteller auswählen",
        NumberOfTyres: "Anzahl Reifen",
        PickupDays: "Pickup Zeitraum",
        TimePeriod: "Zeitraum",
        Device: "Gerät",
        Measurements: "Messungen",
        NoMeasurementsFound: "Es wurden keine Messungen gefunden.",
        DiagnoseType: "Diagnosetyp",
        EntryDiagnose: "Eingangang",
        ExitDiagnose: "Ausgang",
        "30MIN": "30 Minuten",
        "3Days": "3 Tage",
        "7Days": "7 Tage",
        MeasurementsMatchingStorage: "Passend zur Einlagerung",
        MeasurementsNotMatchingStorage: "Nicht passend zur Einlagerung",
        "MeasurementWheel.1": "Vorne links",
        "MeasurementWheel.2": "Vorne rechts",
        "MeasurementWheel.3": "Hinten rechts",
        "MeasurementWheel.5": "Hinten links",
        AdoptValuesIntoStorage: "Werte in Einlagerung übernehmen",
        MeasurementAdopted: "Messwerte übernommen",
        MeasurementAdoptedMessage: "Einlagerung speichern?",

        StorageAlreadyExistsHeader: "Fehler",
        StorageAlreadyExistsMessage:
            "Es existiert bereits eine Einlagerung mit gleicher Nummer und Typ.",

        UpdateFrontAndRearTyreHeader: "Frage",
        UpdateFrontAndRearTyreMessage:
            "Reifen für Vorne und Hinten übernehmen?",

        "storage.active.true": "Eingelagert",
        "storage.active.false": "Ausgelagert",
        "storage.type.SUMMER": "Sommer",
        "storage.type.WINTER": "Winter",
        "storage.type.ALLSEASON": "Ganzjahr",
        "storage.state.OKAY": "Gut",
        "storage.state.MARGINAL": "Grenzwertig",
        "storage.state.RISKY": "Gefährlich",
        "storage.state.UNKNOWN": "Unbekannt",
        "storage.status.DONE": "Fertig",
        "storage.status.INWORK": "In Bearbeitung",
        "storage.status.CHECK": "Zu Prüfen",
        "rim.type.ALU": "Alu",
        "rim.type.STEEL": "Stahl",
        "rim.type.ALLSEASON": "Ganzjahr",
        "rim.type.NONE": "Ohne",
        "wheel.position.FRONT_LEFT": "Vorne Links",
        "wheel.position.FRONT_RIGHT": "Vorne Rechts",
        "wheel.position.REAR_LEFT": "Hinten Links",
        "wheel.position.REAR_RIGHT": "Hinten Rechts",
        "wheel.position.SPARE": "Ersatzrad",

        ChartStorageTypeTitle: "Typ",
        ChartStorageActiveTitle: "Status",
        ChartStorageStateTitle: "Zustand",
        ChartStorageStatusTitle: "Bearbeitung",
        ChartStorageProfileDepthTile: "Profiltiefen (mm)",
        ChartStorageTyreManufacturerTitle: "Reifenhersteller",
        ChartStorageTyreDiameterTitle: "Reifendurchmesser",
        ChartStorageOverdueTitle: "Überfällig",

        "chart.storage.active.series.name": "Einlagerungen",
        "chart.storage.tyre.diameter.series.name": "Reifen",
        "chart.storage.tyre.manufacturer.series.name": "Reifen",
        "chart.storage.tyre.profile.depth.series.name": "Reifen",
        "chart.storage.overdue.series.name": "Einlagerungen",
        "chart.storage.state.series.name": "Einlagerungen",
        "chart.storage.status.series.name": "Einlagerungen",

        // Vehicles
        VehicleRegistration: "Fahrzeugschein",
        FleetsCard: "Flottenkarte",
        TagNumber: "Kennzeichen",
        VehicleType: "Fahrzeugtyp",
        SearchVehicleType: "Fahrzeugtyp suchen",
        HSNTSN: "HSN/TSN",
        HSN: "HSN",
        TSN: "TSN",
        VehicleFirstRegistration: "Erstzulassung",
        VIN: "VIN",
        Inspections: "Wartungen",
        Mileage: "Kilometerstand",
        HU: "HU",
        LastInspection: "Letzte Inspektion",
        LastAirConditionInspection: "Letzte Klimawartung",
        LeasingAndFleet: "Leasing / Flotte",
        LeasingVehicle: "Leasingfahrzeug",
        LeasingNumber: "Leasingnummer",
        FleetsVehicle: "Flottenfahrzeug",
        FleetsNumber: "Flottennummer",
        Driver: "Fahrer",
        DriverHasBirthday: "Fahrer hat Geburtstag",
        HURequiredAt: "TÜV fällig am",
        RequiredAt: "Benötigt am",
        Vehicles: "Fahrzeuge",
        NewVehicle: "Neues Fahrzeug",
        ExistingVehicle: "Bestandsfahrzeug",
        VehicleSearch: "Fahrzeugsuche",
        ChangeVehicle: "Fahrzeug ändern",
        NoVehiclesFound: "Keine Fahrzeuge gefunden",
        BuildYear: "Baujahr",
        EngineCapacity: "Leistung",
        FirstAidkitExpired: "Verbandskasten abgelaufen",
        FirstAidkitExpiry: "Verbandskasten abgelaufen",
        EnginePowerKW: "Leistung (kW)",
        EngingCapacity: "Hubraum",
        MaxSpeed: "Höchstgeschwindigkeit",
        FuelType: "Kraftstoffart",
        EmptyWeight: "Leergewicht",
        NameOrCompany: "Name oder Firma",
        AddVehicle: "Neues Fahrzeug",

        ScanVehicleTag: "Kennzeichen scannen",
        ScanningForVehicleTag: "Suche nach Kfz-Kennzeichen",
        VehicleTagNotFound: "Es wurden kein Kennzeichen gefunden.",

        ScanVehicleRegistration: "Fahrzeugschein scannen",
        ScanningForVehicleRegistration: "Suche nach Fahrzeugschein",
        VehicleRegistrationNotFound: "Es wurde kein Fahrzeugschein gefunden",
        TakeOverVehicleRegistration: "Fahrzeugschein übernehmen",

        ScanMileage: "Kilometerstand scannen",
        ScanningForMileage: "Suche nach Kilometerstand",
        MileageNotFound: "Es wurde kein Kilometerstand gefunden.",

        VehicleAlreadyExistsHeader: "Fehler",
        VehicleAlreadyExistsMessage: "Fahrzeug existiert bereits.",

        NoDataFoundForVehicleTag:
            "Es wurden keine Date für das Kennzeichen gefunden.",

        // Bookings
        Services: "Dienstleistungen",
        Progress: "Fortschritt",
        Ressource: "Ressource",
        VehicleDelivery: "Kfz-Abgabe",
        VehiclePickup: "Kfz-Abholung",
        Processor: "Bearbeiter",
        Description: "Beschreibung",
        NoBookingsFound: "Keine Termin gefunden",
        HasStorage: "Mit Einlagerung",
        Service: "Dienstleistung",
        Resource: "Ressource",
        TodayBookings: "Termine von Heute",
        PastBookings: "Vergangene Termine",
        FutureBookings: "Zukünftige Termine",

        "booking.state.open": " Offen",
        "booking.state.finished": " Erledigt",
        "booking.state.cancelled": " Storniert",
        "booking.state.proposed": " Nicht bestätigt",
        "booking.state.rejected": " Abgelehnt",

        "booking.progress.WAITING": " Warten auf Fahrzeug",
        "booking.progress.DELIVERED": "Fahrzeug vor Ort",
        "booking.progress.PROCESSING": "In Bearbeitung",
        "booking.progress.FINISHED": "Fertiggestellt",
        "booking.progress.PICKEDUP": "Abgeholt",

        // Images
        DeleteImageHeader: "Bild Löschen",
        DeleteImageMessage: "Möchten Sie das Bild unwiederruflich löschen?",
        NoImagesFound: "Keine Bilder gefunden",

        "image.damage": "Beschädigung",
        "image.front": "Vorderseite",
        "image.back": "Rückseite",
        "image.vehicle": "Fahrzeug",
        "image.vehicle.registration": "Fahrzeugschein",
        "image.vehicle.leasingcard": "Leasingkarte",
        "image.vehicle.fleetcard": "Flottenkarte",
        "image.storage": "Einlagerung",
        "image.storage.tyre": "Reifen",
        "image.storage.tyre.fl": "Reifen (VL)",
        "image.storage.tyre.fr": "Reifen (VR)",
        "image.storage.tyre.rl": "Reifen (HL)",
        "image.storage.tyre.rr": "Reifen (HR)",
        "image.storage.rim": "Felge",
        "image.storage.rim.fl": "Felge (VL)",
        "image.storage.rim.fr": "Felge (VR)",
        "image.storage.rim.rl": "Felge (HL)",
        "image.storage.rim.rr": "Felge (HR)",

        // Voucher
        Order: "Auftrag",
        Voucher: "Beleg",
        Vouchers: "Belege",
        VoucherType: "Art",
        VoucherItem: "Position",
        VoucherItems: "Positionen",
        NetPrice: "Netto",
        GrossPrice: "Brutto",
        SalesPrice: "VK-Preis",
        Seller: "Verkäufer",
        LastEditDate: "Letzte Bearbeitung",
        Quantity: "Menge",
        VatRate: "MwSt.",
        NoVouchersFound: "Keine Belege gefunden",

        DeleteVoucherItemHeader: "Löschen",
        DeleteVoucherItemMessage: "Möchten Sie die Position löschen?",
        OverwritePriceHeader: "Preis überschreiben",
        OverwritePriceMessage: "Möchten Sie den Preis überschreiben?",

        "voucher.type.EL": "Einlagerungsbeleg",
        "voucher.type.AN": "Angebot",
        "voucher.type.RS": "Reservierung",
        "voucher.type.KB": "Kundenbestellung",
        "voucher.type.MO": "Montageschein",
        "voucher.type.LS": "Lieferschein",
        "voucher.type.GU": "Guschrift",
        "voucher.type.WG": "Wertgutschrift",
        "voucher.type.AR": "Rechnung",

        // RTS
        "EXIT DIAGNOSIS": "Ausgangsdiagnose",
        "ENTRY DIAGNOSIS": "Eingangsdiagnose",

        // Chat
        AIFunctions: "AI-Funktionen (Beta)",
        ChatWelcome: "Willkommen!",
        ChatIntro: "Wie kann ich Dir heute helfen?",
        ChatDetails: "Du kannst mir Fragen zu deinen Kunden, Fahrzeugen, Einlagerungen und Terminen stellen?",
        YourQuestion: "Deine Frage",
        ClearChat: "Neuer Chat",
        "message.user": "Benutzer",
        "message.system": "AI",
        WheelInformations: "Räderdaten",
        EditWheelInformations: "Räderdaten ändern",
        EditWheelInformationsIntro:
            "Bitte teile Sie mir bitte welche Räderdaten Sie ändern können. Z.B. Profiltiefe vorne links 3mm",
        ApplyWheelInformations: "Räderdaten übernehmen",
        ForExample: "Zum Beispiel",
    },
};
