import { WebdavApiConfig } from "./../webdav-api.config";
import { Entity } from "./entity.model";
import { Branch, Tenant, NeonisApiConfig } from "@yps/neonis-api-ngx";
import { User } from "./user.model";
import { ProparApiConfig } from "@app/propar-api.config";

export type Theme = "auto" | "light" | "dark";

export class NeonisRtsApiConfig extends NeonisApiConfig {
    constructor(config?: Partial<NeonisApiConfig>) {
        super(config);
    }

    enabledForUrl(url: string): boolean {
        if (url.includes("rts")) {
            return false;
        }

        return super.enabledForUrl(url);
    }
}

export class ProparRtsApiConfig extends ProparApiConfig {
    constructor(config?: Partial<ProparRtsApiConfig>) {
        super(config);
    }

    enabledForUrl(url: string): boolean {
        if (url.includes("rts")) {
            return false;
        }

        return super.enabledForUrl(url);
    }
}

export class Settings extends Entity {
    locale: string;
    theme: Theme = "auto";

    user: User;
    branch: Branch;
    tenant: Tenant;

    neonisApi = new NeonisRtsApiConfig();
    proparApi = new ProparRtsApiConfig();
    webdavApi = new WebdavApiConfig();

    storage = {
        showMessages: true,
        pickupDays: 30,
    };

    rts = {
        enabled: false,
        authenticated: false,
        email: "",
        password: "",
    };

    constructor(settings?: Partial<Settings>, mergeWith?: Partial<Settings>) {
        super(settings);

        this.assign(settings);
        this.assign(mergeWith);
    }

    assign(settings?: Partial<Settings>): Settings {
        if (settings) {
            Object.assign(this, settings);

            this.user = new User(settings.user);
            this.branch = new Branch(settings.branch);
            this.tenant = new Tenant(settings.tenant);

            this.neonisApi = new NeonisRtsApiConfig(settings.neonisApi);
            this.proparApi = new ProparRtsApiConfig(settings.proparApi);
            this.webdavApi = new WebdavApiConfig(settings.webdavApi);
        }

        return this;
    }
}
