import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import {
    FaIconLibrary,
    FontAwesomeModule,
} from "@fortawesome/angular-fontawesome";

import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far as farPro } from "@fortawesome/pro-regular-svg-icons";
import { fas as fasPro } from "@fortawesome/pro-solid-svg-icons";
import { fad as fadPro } from "@fortawesome/pro-duotone-svg-icons";

import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";
import {
    HideSelectIconModule,
    IonicCommonComponentsModule,
} from "@tcn/ion-common";
import {
    ApiAuthPipeModule,
    ApiUrlPipeModule,
    LocaleCurrencyPipeModule,
    LocaleDatePipeModule,
    LocaleNumberPipeModule,
    NgxCommonDirectivesModule,
} from "@tcn/ngx-common";
import { LoadingIndicatorModule } from "./components/loading-indicator";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { LoadingBarModule } from "./components/loading-bar";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        FontAwesomeModule,
        TranslateModule.forChild(),
        ApiUrlPipeModule,
        ApiAuthPipeModule,
        LoadingIndicatorModule,
        LocaleDatePipeModule,
        LocaleNumberPipeModule,
        LocaleCurrencyPipeModule,
        NgxDatatableModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        IonicModule,
        TranslateModule,
        FontAwesomeModule,
        IonicCommonComponentsModule,
        HideSelectIconModule,
        NgxCommonDirectivesModule,
        LocaleDatePipeModule,
        LocaleNumberPipeModule,
        LocaleCurrencyPipeModule,
        ApiUrlPipeModule,
        ApiAuthPipeModule,
        LoadingIndicatorModule,
        NgxDatatableModule,
    ],
    declarations: [],
})
export class SharedModule {
    constructor(library: FaIconLibrary) {
        library.addIconPacks(fab, fas, far, fasPro, farPro, fadPro);
    }
}
