import { NgModule } from "@angular/core";
import { NoPreloading, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
    {
        path: "signin",
        loadChildren: () =>
            import("./pages/signin/signin.module").then(
                (m) => m.SigninPageModule
            ),
    },
    {
        path: "app",
        loadChildren: () =>
            import("./tabs/tabs.module").then((m) => m.TabsPageModule),
    },
    {
        path: "voucher-details/:id",
        loadChildren: () =>
            import(
                "./modules/voucher/pages/voucher-details/voucher-details.module"
            ).then((m) => m.VoucherDetailsPageModule),
    },
    {
        path: "",
        redirectTo: "/signin",
        pathMatch: "full",
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {
            preloadingStrategy: NoPreloading,
        }),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {}
