import { NgModule } from "@angular/core";
import { SharedModule } from "@app/shared.module";
import { TextEditorPage } from "./text-editor.page";

@NgModule({
  imports: [SharedModule],
  declarations: [TextEditorPage],
  exports: [TextEditorPage],
  entryComponents: [TextEditorPage]
})
export class TextEditorPageModule {}
