import { CustomerQuery } from "@yps/neonis-api-ngx";
import { Entity } from "../../../models/entity.model";

export class CustomerFilter extends Entity {
    name: string;
    query: CustomerQuery;


    constructor(filter?: Partial<CustomerFilter>) {
        super(filter);
        this.assign(filter);
    }

    assign(filter: Partial<CustomerFilter>): CustomerFilter {
        if (filter) {
            Object.assign(this, filter);
            this.query = new CustomerQuery(filter.query);
        }

        this.query = this.query || new CustomerQuery();

        return this;
    }

    get empty(): boolean {
        return !this.query || this.query.empty;
    }

    equals(filter: Partial<CustomerFilter>): boolean {
        if (!filter || !filter.query) {
            return false;
        }

        return this.query.equals(filter.query) && this.name === filter.name;
    }
}
