import { Injectable } from "@angular/core";
import { ApiConfig, ApiConfigService, ApiInterceptor } from "@tcn/ngx-common";

@Injectable({
    providedIn: "root"
})
export class ProparApiInterceptor extends ApiInterceptor {
    constructor(private apiConfigService: ApiConfigService) {
        super();
    }

    get config(): ApiConfig {
        return this.apiConfigService.config("propar.api.v1");
    }
}
