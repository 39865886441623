import { Component, OnInit, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
    selector: "app-text-editor",
    templateUrl: "./text-editor.page.html",
    styleUrls: ["./text-editor.page.scss"]
})
export class TextEditorPage implements OnInit {
    @Input()
    title = "Edit";

    @Input()
    text: string;

    constructor(public modalCtrl: ModalController) {}

    ngOnInit() {}

    cancel() {
        this.modalCtrl.dismiss({
            cancelled: true,
            text: this.text
        });
    }

    save() {
        this.modalCtrl.dismiss({
            cancelled: false,
            text: this.text
        });
    }
}
