import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LoadingIndicatorComponent } from "./loading-indicator.component";

@NgModule({
    imports: [CommonModule],
    exports: [LoadingIndicatorComponent],
    declarations: [LoadingIndicatorComponent]
})
export class LoadingIndicatorModule {}
