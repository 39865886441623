import { Injectable } from "@angular/core";
import { Action, Reduce } from "@tcn/ngx-redux-store-config";
import { from, Observable, of } from "rxjs";
import { delay } from "rxjs/operators";
import { StorageFilter } from "../../models/storage-filter.model";
import { AppState } from "../app-state";
import { INITIAL_STATE } from "../initital-state";
import { LocalStorage } from "@app/services/local-storage.service";

@Injectable()
export class StorageApi {
    constructor(private storage: LocalStorage) {}

    @Action(null, { cache: { expires: false, return: "storageFilters" } })
    loadFilters(): Promise<StorageFilter[]> {
        return this.storage.get("storage.filters").then((filters) => {
            const result = (filters || []).map(
                (filter) => new StorageFilter(filter)
            );

            return result;
        });
    }

    @Action()
    saveFilters(filters: Array<StorageFilter>): Observable<StorageFilter[]> {
        return from(this.storage.set("storage.filters", filters));
    }

    @Action()
    currentFilter(filter: StorageFilter): Observable<StorageFilter> {
        return of(filter).pipe(delay(100));
    }

    @Reduce(["loadFilters", "saveFilters"])
    protected reduceFilters(state = INITIAL_STATE, action): AppState {
        return {
            ...state,
            storageFilters: action.payload.map(($) => new StorageFilter($)),
        };
    }

    @Reduce()
    protected _currentFilter(state = INITIAL_STATE, action): AppState {
        return {
            ...state,
            currentStorageFilter: new StorageFilter(action.payload),
        };
    }
}
