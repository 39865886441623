import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";

@Injectable({ providedIn: "root" })
export class ViewStateService {
    private states = {};

    setState(url: string, state: any) {
        const stateEntry = this.getStateEntry(url);
        stateEntry.data = state;
        stateEntry.subject.next(state);
    }

    getState(url: string, defaultValue = {}): any {
        const stateEntry = this.states[url];
        if (stateEntry == null) {
            return defaultValue;
        }

        return stateEntry.data;
    }

    watchState(url: string): Observable<any> {
        return this.getStateEntry(url).subject.asObservable();
    }

    private getStateEntry(url: string) {
        let stateEntry = this.states[url];
        if (stateEntry == null) {
            stateEntry = {
                data: {},
                subject: new Subject<any>()
            };

            this.states[url] = stateEntry;
        }

        return stateEntry;
    }
}
