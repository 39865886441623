import { NgReduxModule } from "@angular-redux/store";
import { NgModule } from "@angular/core";
import { CustomerStoreModule } from "@app/modules/customer/store/module";
import { StoreConfigModule } from "@tcn/ngx-redux-store-config";
import { BookingStoreModule } from "./../modules/booking/store/module";
import { StorageStoreModule } from "./../modules/storage/store/module";
import { CommonApi, SettingsApi, TagsApi } from "./api";
import { ConfigApi } from "./api/config.api";
import { INITIAL_STATE } from "./initial-state";
import { VehicleStoreModule } from "@app/modules/vehicle/store/module";

@NgModule({
    imports: [
        NgReduxModule,

        CustomerStoreModule,
        VehicleStoreModule,
        StorageStoreModule,
        BookingStoreModule,

        StoreConfigModule.forRoot({
            initState: INITIAL_STATE,
            withLogger: false,
        }),
    ],

    providers: [CommonApi, SettingsApi, TagsApi, ConfigApi],
})
export class StoreModule { }
