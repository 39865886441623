import { Injectable } from "@angular/core";
import {
    Action,
    ACTION_ERROR,
    ACTION_FINISH,
    ACTION_START,
    Reduce,
    StoreAction,
} from "@tcn/ngx-redux-store-config";
import { AppState } from "../app-state";
import { INITIAL_STATE } from "../initial-state";

@Injectable()
export class CommonApi {
    static readonly SET_LOADING = "SET_LOADING";
    static readonly SET_ERROR = "SET_ERROR";

    static readonly LOAD_SETTINGS = "LOAD_SETTINGS";
    static readonly SAVE_SETTINGS = "SAVE_SETTINGS";

    constructor() {}

    setLoading = (loading: boolean): StoreAction => ({
        type: CommonApi.SET_LOADING,
        payload: loading,
    });

    setError = (error: any): StoreAction => ({
        type: CommonApi.SET_ERROR,
        payload: error,
    });

    @Reduce(CommonApi.SET_LOADING)
    private setLoadingReducer(
        state: AppState = INITIAL_STATE,
        action
    ): AppState {
        return { ...state, loading: action.payload };
    }

    @Reduce(CommonApi.SET_ERROR)
    private setErrorReducer(
        state: AppState = INITIAL_STATE,
        action: StoreAction
    ): AppState {
        return { ...state, error: action.payload, loading: false };
    }

    @Reduce(ACTION_START)
    private actionStartReducer(
        state: AppState = INITIAL_STATE,
        action
    ): AppState {
        return { ...state, loading: true };
    }

    @Reduce(ACTION_FINISH)
    private actionFinishReducer(
        state: AppState = INITIAL_STATE,
        action
    ): AppState {
        return { ...state, loading: false };
    }

    @Reduce(ACTION_ERROR)
    private actionErrorReducer(
        state: AppState = INITIAL_STATE,
        action
    ): AppState {
        return { ...state, error: action.payload.error, loading: false };
    }
}
