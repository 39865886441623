import { Injectable } from "@angular/core";

@Injectable({ providedIn: "root" })
export class RatingService {
    constructor() {}

    promptForRating(lang, immediately: boolean) {}

    navigateToAppStore() {}
}
