export class DeviceInfo {
    createdOn: Date;
    lastUsedOn: Date;

    uuid: string;
    name: string;
    user: string;
    appVersion: string;
    manufacturer: string;
    model: string;
    operatingSystem: string;
    osVersion: string;
    platform: string;
    note: string;

    constructor(device?: Partial<DeviceInfo>) {
        if (device) {
            Object.assign(this, device);
        }

        // Default values
        this.createdOn = this.createdOn || new Date();
        this.lastUsedOn = this.lastUsedOn || new Date();
    }
}
