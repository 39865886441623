import { Component, OnInit } from "@angular/core";
import { ComponentController } from "@tcn/ngx-common";
import { LoadingService } from "./../../services/loading.service";

@Component({
    selector: "loading-indicator",
    templateUrl: "./loading-indicator.component.html",
    styleUrls: ["./loading-indicator.component.scss"]
})
export class LoadingIndicatorComponent extends ComponentController
    implements OnInit {
    visible = false;

    constructor(private loadingSevice: LoadingService) {
        super();
    }

    ngOnInit() {
        /*
        this.loadingSevice.isLoading
            .pipe(takeUntil(this.destroyed$))
            .subscribe(loading => (this.visible = loading));
            */
    }
}
