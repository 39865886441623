import { NgModule } from "@angular/core";
import { SharedModule } from "@app/shared.module";

import { ImagesFilterPage } from "./images-filter.page";

@NgModule({
  imports: [SharedModule],
  declarations: [ImagesFilterPage]
})
export class ImagesFilterPageModule {}
