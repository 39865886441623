import { BookingQuery, WheelStorageQuery } from "@yps/neonis-api-ngx";
import { Entity } from "../../../models/entity.model";

export class BookingFilter extends Entity {
    name: string;
    query: BookingQuery;

    constructor(filter?: Partial<BookingFilter>) {
        super(filter);
        this.assign(filter);
    }

    assign(filter: Partial<BookingFilter>): BookingFilter {
        if (filter) {
            Object.assign(this, filter);
            this.query = new BookingQuery(filter.query);
        }

        this.query = this.query || new BookingQuery();

        return this;
    }

    get empty(): boolean {
        return !this.query || this.query.empty;
    }

    equals(filter: Partial<BookingFilter>): boolean {
        if (!filter || !filter.query) {
            return false;
        }

        return this.query.equals(filter.query) && this.name === filter.name;
    }
}
