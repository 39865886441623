import { ModalController } from "@ionic/angular";
import {
    Component,
    Input,
    ViewChild,
    ElementRef,
    AfterViewInit,
} from "@angular/core";
import { Image } from "@yps/neonis-api-ngx";

@Component({
    selector: "app-image-viewer",
    templateUrl: "./image-viewer.page.html",
    styleUrls: ["./image-viewer.page.scss"],
})
export class ImageViewerPage implements AfterViewInit {
    @ViewChild("imageContainer", { static: false })
    imageContainer: ElementRef;

    @ViewChild("myPinch", { static: false }) myPinch;

    @Input()
    image: Image;

    properties = {
        zoomControlScale: 2,
        limitZoom: 5,
    };

    constructor(private modalCtrl: ModalController) {}

    ngAfterViewInit() {
        /*
        panzoom(this.imageContainer.nativeElement, {
            transformOrigin: { x: 0.5, y: 0.5 },
            minZoom: 1,
            bounds: true,
            boundsPadding: 0.1,
        });
        */
    }

    reset() {
        this.myPinch.toggleZoom();

        /*
        panzoom(this.imageContainer.nativeElement, {
            transformOrigin: { x: 0.5, y: 0.5 },
            minZoom: 1,
            bounds: true,
            boundsPadding: 0.1,
        });
        */
    }

    close() {
        this.modalCtrl.dismiss();
    }
}
