import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";

@Component({
  selector: "app-images-filter",
  templateUrl: "./images-filter.page.html",
  styleUrls: ["./images-filter.page.scss"]
})
export class ImagesFilterPage implements OnInit {
  constructor(private modalCtrl: ModalController) {}

  ngOnInit() {}

  close() {
    this.modalCtrl.dismiss({
      dismissed: true
    });
  }
}
