import { ApiConfig } from "@tcn/ngx-common";

export class ProparApiConfig extends ApiConfig {
    constructor(config?: Partial<ApiConfig>) {
        super({
            name: "propar.api.v1",
            scheme: "http",
            host: null,
            domain: "myneonis.de",
            port: 80,
            baseUrl: "/mitarbeiter/seam/resource/rest/propar/app",

            headers: [
                {
                    name: "Content-Type",
                    value: "application/json",
                },
                {
                    name: "Accept",
                    value: "application/json",
                },
            ],

            auth: {
                token: {
                    header: "PROPAR-AUTH-TOKEN",
                    value: "",
                },
            },

            mappings: [
                {
                    name: "propar.api.device.v1",
                    path: `/devices`,
                },
                {
                    name: "propar.api.vehicles.v1",
                    path: `/vehicles`,
                },
                {
                    name: "propar.api.storages.v1",
                    path: `/storages`,
                },
                {
                    name: "propar.api.contact.v1",
                    path: `/contact/mail`,
                },
            ],
        });

        this.assign(config);
    }
}
