import { Injectable, NgZone } from "@angular/core";
import { App } from "@capacitor/app";

@Injectable({ providedIn: "root" })
export class BackActionService {
    actions: Array<() => void> = [];
    current: () => void;

    constructor(private zone: NgZone) {
        // Add listener for the back button event
        App.addListener("backButton", () => {
            this.zone.run(() => {
                if (this.current != null) {
                    this.current();
                }
            });
        });
    }

    /**
     * Pushes a new action onto the stack and sets it as the current action
     * @param action The action function to be executed when the back button is pressed
     */
    push(action: () => void) {
        this.actions.push(action);
        this.current = action;
    }

    /**
     * Pops the latest action from the stack and sets the previous one as the current action
     */
    pop() {
        this.current = this.actions.pop();
    }
}
