import { ApiConfig } from "@tcn/ngx-common";
import { environment } from "@environment";

export class WebdavApiConfig extends ApiConfig {
    constructor(config?: Partial<ApiConfig>) {
        super({
            name: "webdav.api.v1",
            ...environment.webdav,
            mappings: [
                {
                    name: "webdav.api.images.v1",
                    path: ""
                }
            ]
        });

        this.assign(config);
    }
}
