import { NgModule } from "@angular/core";
import { SharedModule } from "@app/shared.module";
import { ImageViewerPage } from "./image-viewer.page";
import { PinchZoomModule } from "ngx-pinch-zoom";

@NgModule({
    imports: [SharedModule, PinchZoomModule],
    exports: [ImageViewerPage],
    declarations: [ImageViewerPage],
    entryComponents: [ImageViewerPage],
})
export class ImageViewerPageModule {}
