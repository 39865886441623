import { takeUntil } from "rxjs/operators";
import { Observable } from "rxjs";
import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Item, Items, unique, ComponentController } from "@tcn/ngx-common";
import { Tag } from "@yps/neonis-api-ngx";
import { TagsApi } from "@app/store/api";
import { select } from "@angular-redux/store";
import { LocalizedAlertController } from "@tcn/ion-common";

@Component({
    selector: "app-tag-selection",
    templateUrl: "./tag-selection.page.html",
    styleUrls: ["./tag-selection.page.scss"],
})
export class TagSelectionPage extends ComponentController {
    @select()
    tags$: Observable<Tag[]>;

    @Input()
    allowNewTags = false;

    @Input()
    set selected(selected: Tag[]) {
        this._selected = selected;

        // Make sure that also the selected tags are included in the tag items.
        this.tagItems = new Items(
            unique([...this.tagItems.allValues, ...selected], "name")
        );

        this.tagItems.selectAllBy(selected, "name");
    }

    search: string;
    tagItems = new Items([]);
    private _selected: Tag[];

    constructor(
        private alertCtrl: LocalizedAlertController,
        private modalCtrl: ModalController,
        private tagsApi: TagsApi
    ) {
        super();
    }

    ngOnInit() {
        this.tagsApi.loadTags();

        this.tags$.pipe(takeUntil(this.destroyed$)).subscribe((tags) => {
            this.tagItems = new Items(tags);
            this.tagItems.selectAllBy(this._selected, "name");
        });
    }

    cancel() {
        this.modalCtrl.dismiss({
            cancelled: true,
        });
    }

    save() {
        this.modalCtrl.dismiss({
            cancelled: false,
            selected: this.tagItems.selectedValues(true),
        });
    }

    searchTags() {
        const search = this.search ? this.search.toLowerCase().trim() : null;

        if (this.search) {
            this.tagItems.all.forEach((item) => {
                item.hidden = !item.value.name.toLowerCase().includes(search);
            });
        } else {
            this.tagItems.showAll();
        }
    }

    async editTag(item: Item) {
        const tag = item.value;
        const oldName = tag.name;

        const alert = await this.alertCtrl.create({
            header: "Save",
            inputs: [
                {
                    name: "name",
                    type: "text",
                    value: tag.name,
                    placeholder: "Name",
                },
            ],
            buttons: [
                {
                    text: "Cancel",
                    role: "cancel",
                    cssClass: "secondary",
                    handler: () => {
                        tag.name = oldName;
                    },
                },
                {
                    text: "Ok",
                    handler: (input) => {
                        if (!input.name) {
                            return;
                        }

                        tag.name = input.name;
                        return this.tagsApi.saveTag(tag).subscribe((tag) => {
                            item.value = tag;
                        });
                    },
                },
            ],
        });

        alert.present();
    }

    createTagFromSearch() {
        const newTag = new Tag({
            name: this.search,
        });

        this.tagsApi.addTag(newTag).subscribe((tag) => {
            //const item = new Item(tag);
            //item.selected = true;
            //this.tagItems.add(item);
            this.tagItems.selectAllBy(this._selected, "name");
            this.tagItems.showAll();
        });
    }
}
