// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,

    appName: "PROPAR APP",
    appVersion: "1.6.0",

    contact: {
        email: "info@ypsystems.de",
        phone: "02054-9690830",
    },

    defaultPage: "/app/tabs/home",

    neonisApi: {
        scheme: "http",
        port: 80,
    },

    proparApi: {
        scheme: "http",
        port: 80,
    },

    webdav: {
        scheme: "http",
        host: "webdav",
        domain: "ypsystems.de",
        port: 80,
        baseUrl: "/neonis",

        auth: {
            basic: {
                username: "propar",
                password: "ProparWebdav#110",
            },
        },
    },

    imageCategories: [
        "image.damage",
        "image.vehicle",
        "image.vehicle.registration",
        "image.vehicle.leasingcard",
        "image.vehicle.fleetcard",
        "image.storage",
        "image.storage.tyre",
        "image.storage.tyre.fl",
        "image.storage.tyre.fr",
        "image.storage.tyre.rl",
        "image.storage.tyre.rr",
        "image.storage.rim",
        "image.storage.rim.fl",
        "image.storage.rim.fr",
        "image.storage.rim.rl",
        "image.storage.rim.rr",
    ],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
