import { Injectable } from "@angular/core";
import { Action, Reduce } from "@tcn/ngx-redux-store-config";
import { from, Observable, of } from "rxjs";
import { delay } from "rxjs/operators";
import { CustomerFilter } from "../../models/booking-filter.model";
import { AppState } from "../app-state";
import { INITIAL_STATE } from "../initital-state";
import { LocalStorage } from "@app/services/local-storage.service";

@Injectable()
export class CustomerApi {
    constructor(private storage: LocalStorage) {}

    @Action(null, { cache: { expires: false, return: "customerFilters" } })
    loadFilters(): Promise<CustomerFilter[]> {
        return this.storage.get("customer.filters").then((filters) => {
            const result = (filters || []).map(
                (filter) => new CustomerFilter(filter)
            );

            return result;
        });
    }

    @Action()
    saveFilters(filters: Array<CustomerFilter>): Observable<CustomerFilter[]> {
        return from(this.storage.set("customer.filters", filters));
    }

    @Action()
    currentFilter(filter: CustomerFilter): Observable<CustomerFilter> {
        return of(filter).pipe(delay(100));
    }

    @Reduce(["loadFilters", "saveFilters"])
    protected reduceFilters(state = INITIAL_STATE, action): AppState {
        return {
            ...state,
            customerFilters: action.payload.map(($) => new CustomerFilter($)),
        };
    }

    @Reduce()
    protected _currentFilter(state = INITIAL_STATE, action): AppState {
        return {
            ...state,
            currentCustomerFilter: new CustomerFilter(action.payload),
        };
    }
}
